body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-header {
  background-color: #282c44;
  min-height: 3vh;
  height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: 16px;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.task-header {
	font-size: 10pt;
	padding: 4px;
	cursor:pointer;
	background-color: black;
	color: white;
	-webkit-user-select: none;
	        user-select: none;
}
 
 .group-header {
	font-size: 10pt;
	padding: 4px;
	cursor:pointer;
	background-color: rgb(32, 32, 56);
	color: white;
	-webkit-user-select: none;
	        user-select: none;
 }

.task-row {
  font-size: 10pt;
  margin: 8px;
  cursor:pointer;
  -webkit-user-select: none;
          user-select: none;
}

.task-row:hover {
	background-color: rgb(251, 186, 66) !important;
	color: white;
}

.cutoff-line {
	border-top: black 2px solid;
	border-bottom: black 2px solid;
	line-height: 1px;
	min-height: 2px;
	width: 100%;
	text-align: center;
	font-size: 8pt;
}

.number {
	text-align: right;
}

.pad-bottom {
	line-height: 48px;
}

.label {
	font-size: 10pt;
	text-align: end;
	margin-right: 8px;
	font-weight: bold;
	padding-top: 2px;
	padding-right: 8px;
}
